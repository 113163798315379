<template>
  <b-container class="mt-5 contactInfo">
    <b-row>
      <b-col md="4">
        <h3 class="mb-3">Contact Us</h3>
        <p class="text-nowrap">
          <span class="mr-2">
            <i class="fas fa-phone-square-alt"></i>
          </span>
          <a href="tel:+1 (540) 310-8871">(540) 310-8871</a>
        </p>
        <p class="text-nowrap">
          <span class="mr-2">
            <i class="fas fa-envelope-square"></i>
          </span>
          <a href="mailto: info@abpoolsva.com">info@abpoolsva.com</a>
        </p>
        <p class="text-nowrap">
          <span class="mr-2">
            <i class="fas fa-calendar-week"></i>
          </span>
          M-F: 8:30 a.m. to 5:00 p.m.
        </p>
        <p class="text-nowrap">
          <span class="mr-2">
            <i class="fas fa-calendar-day"></i>
          </span>
          Saturday: 9:00 a.m. to 3:00 p.m.
        </p>
        <p class="text-nowrap">
          <span class="mr-2">
            <i class="fas fa-calendar-times"></i>
          </span>
          Sunday: By Appointment Only
        </p>
      </b-col>

      <b-col md="8" class="msgCol">
        <h3 class="mb-3">Send Us a Message</h3>
        <b-form id="postMessage" @reset="onReset" @submit="onSubmit">
          <b-form-row>
            <b-col>
              <b-form-group
                id="emailGroup"
                label="Email address:"
                label-for="emailInput"
              >
                <b-form-input
                  id="emailInput"
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Enter Email..."
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="5">
              <b-form-group
                id="fnameGroup"
                label="First Name:"
                label-for="fnameInput"
              >
                <b-form-input
                  id="fnameInput"
                  v-model="form.fname"
                  required
                  placeholder="Enter First Name..."
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="7">
              <b-form-group
                id="lnameGroup"
                label="Last Name:"
                label-for="lnameInput"
              >
                <b-form-input
                  id="lnameInput"
                  v-model="form.lname"
                  required
                  placeholder="Enter Last Name..."
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                id="phoneGroup"
                label="Phone Number:"
                label-for="phoneInput"
              >
                <b-form-input
                  id="phoneInput"
                  v-model="form.phone"
                  required
                  placeholder="Enter Phone Number..."
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                id="addressGroup"
                label="Address:"
                label-for="addressInput"
              >
                <b-form-input
                  id="addressInput"
                  v-model="form.address"
                  required
                  placeholder="Enter General Address..."
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                id="descGroup"
                label="Description:"
                label-for="descInput"
              >
                <b-form-textarea
                  id="descInput"
                  v-model="form.desc"
                  placeholder="Enter a short description..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger" class="ml-3">Reset</b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:body>
          <div class="text-center">
            <span class="modal-check"><i class="far fa-check-circle"></i></span>
            <p class="modal-text ml-2">{{ modalMessage }}</p>
          </div>
        </template>
      </Modal>
    </b-row>
  </b-container>
</template>

<script>
import { HTTP } from "../http-common";
import Modal from "@/components/Modal.vue";

export default {
  name: "Contact",

  components: {
    Modal
  },

  data() {
    return {
      form: {
        email: "",
        fname: "",
        lname: "",
        phone: "",
        desc: "", 
        address: ""
      },
      isModalVisible: false,
      modalMessage: ""
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      HTTP.post("/inquiry", this.form).then(response => {
        this.showModal(response);
      });
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.fname = "";
      this.form.lname = "";
      this.form.phone = "";
      this.form.desc = "";
      this.form.address = "";
      // this.form.checked = [];
    },

    showModal(r) {
      if (r.data.response == "success") {
        this.modalMessage = "Message Sent!!!";
        this.isModalVisible = true;
        // Reset our form values
        this.form.email = "";
        this.form.fname = "";
        this.form.lname = "";
        this.form.phone = "";
        this.form.desc = "";
        this.form.address = "";
        // this.form.checked = [];
      }
    },

    closeModal() {
      this.isModalVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.modal-check {
  color: green;
}

.modal-text {
  font-size: $base-font-size * 0.3;
  font-family: "Roboto", sans-serif;
  display: inline;
}

.contactInfo p {
  font-size: $base-font-size * 0.9;
  font-family: "Roboto", sans-serif;
  a {
    font-size: $base-font-size * 0.9;
    font-family: "Roboto", sans-serif;
    color: black;
    // font-weight: bold;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.contactInfo form {
  font-size: $base-font-size * 0.9;
  font-family: "Roboto", sans-serif;
}

@media #{$mq-phone} {
  .msgCol {
    margin-top: 30px;
  }
}
</style>
